.game-price-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  width: 250px;
  height: 400px; /* Set a fixed height for the card */
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.game-image {
  width: 100%;
  height: 95px;
  object-fit: cover;
}

.game-title {
  text-align: center;
  font-size: 18px;
  margin: 10px 0;
}

.store-price-list {
  padding: 10px;
  flex-grow: 1; /* Allows the list to take up remaining space */
  overflow-y: auto; /* Enable scrolling if the list exceeds the container height */
}

/* Custom scrollbar styles */
.store-price-list::-webkit-scrollbar {
  width: 8px;
}

.store-price-list::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track (background) */
  border-radius: 10px;
}

.store-price-list::-webkit-scrollbar-thumb {
  background-color: #cacaca; /* Scrollbar thumb (the draggable part) */
  border-radius: 10px;
}

.store-price-list::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}

.store-price-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 16px;
}

.store-name {
  font-weight: 500;
  font-size: 14px;
}

.price {
  color: #4caf50;
  font-weight: bold;
}

.wishlist-button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  background-color: #c7c7c7;
  width: 100%;
  border: none;
  /* border-radius: 0 0 0px 0px; */
  padding: 7px;
  transition: 0.2s;
  cursor: pointer;
}

.wishlist-button:hover {
  background-color: #919191;
  color: #ebebeb;
}

.active-alert {
  background-color: #d14637;
  color: white;
  padding: 5px;
  text-align: center;
  font-size: 14px;
}

.active-alert:hover {
  background-color: #c0392b;
}
