/* src/components/SignupForm.css */
.signup-form-container {
  width: 400px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 5px;
  /* background-color: #fdfdfd; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 28px 28px 50px rgba(13, 39, 80, 0.16),
    -23px -23px 45px rgba(255, 255, 255, 1);
}

.signup-h1 {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  font-size: 3em;

  background: linear-gradient(
    to right,
    #0b72897c,
    #0b7189
  ); /* Adjust gradient colors as needed */
  -webkit-background-clip: text; /* For Safari */
  -webkit-text-fill-color: transparent; /* For Safari */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group input {
  font-family: "Poppins", sans-serif;
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.signup-button {
  font-family: "Poppins", sans-serif;
  width: 100%;
  padding: 10px;
  background-color: #4d96a7;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.signup-button:hover {
  background-color: #0b7289;
}

.has-account {
  color: #0b7189;
  text-decoration: none;
  text-align: center;
}
.has-account:hover {
  text-decoration: underline;
}

.has-account p {
  margin-bottom: auto;
  font-weight: 500;
}

.error-message {
  color: #d9534f;
  text-align: center;
}
