.search-bar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* margin-bottom: 50px; */
}

.search-bar input {
  width: 300px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
  width: 800px;
  font-family: "Poppins", sans-serif;
}

.search-bar button {
  background-color: #4d96a7;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  font-family: "Poppins", sans-serif;
  transition: 0.2s;
}

.search-bar button:hover {
  background-color: #3b7885;
}
