.landing-page {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-color: #f5f5f5;
}

.info-card-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
}

.game-price-card-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}
