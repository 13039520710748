.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff5d;
  backdrop-filter: blur(12px);
  padding: 0 20px;
  border-bottom: rgb(206, 210, 216) 1px solid;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  background: linear-gradient(to right, #0b72897c, #0b7189);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

.navbar-menu {
  list-style: none;
  display: flex;
}

.navbar-menu li {
  margin: 0 15px;
}

.navbar-link {
  color: #0b7189;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.2s;
}

.navbar-link:hover {
  background-color: #0b72892c;
}

.navbar-link.active {
  background-color: #0b7189;
  color: white;
}

/* Mobile styles */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #0b7189;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #ffffff;
    width: 100%;
    max-width: 250px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    flex-direction: column;
  }

  .navbar-menu.open {
    display: flex;
  }

  .navbar-menu li {
    margin: 10px 0;
    text-align: right;
  }

  .menu-toggle {
    display: block;
  }
}
