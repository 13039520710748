.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navbar + Landing Page = 100vh */
.app {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  height: 100vh; /* Full viewport height */
  margin: 0; /* Remove default margin */
}

.navbar {
  flex: 0 0 auto; /* Navbar height based on content */
}

.landing-page {
  flex: 1; /* Take the remaining height */
  display: flex; /* Optional: Flexbox for centering content */
  align-items: center; /* Center items vertically */
  justify-content: start; /* Center items horizontally */
  background-color: #f5f5f5; /* Example background color */
}
