.colored-button-link {
  text-decoration: none;
}

.colored-button {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  background-color: #4d96a7;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 32px;
  text-align: center;
  transition: 0.2s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.colored-button:hover {
  background-color: #0b7289d5;
}

.get-started-arrow {
  height: 1.5em;
  margin-left: 10px;
}
