@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  /* margin-top: 30px; */
  white-space: nowrap;
  position: relative;
  width: 1000px;
  height: 80px;
  margin: 0;
  margin-top: 20px;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  content: "";
  /* width: 250px; */
  /* height: 100%; */
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255)
  );
}

.logos:after {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255)
  );
}

.logo_items {
  display: inline-block;
  animation: 20s slides infinite linear;
  height: 80px;
}

.logo_items img {
  height: 60px;
  margin-right: 50px;
}
