/* src/components/InfoCard.css */

.info-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  background-color: #fdfdfd;
  transition: transform 0.2s;
  width: 300px;
  max-height: 200px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.info-card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #0b72892a;
}

.info-card-title {
  font-size: 1.2em;
  font-weight: 600;
  margin: 10px 0 5px;
}

.info-card-description {
  font-size: 0.9em;
  color: rgb(107, 114, 128);
  font-weight: 400;
}

.info-card-icon {
  width: 24px;
  height: 24px;
}
